















































import {Component, Vue} from "vue-property-decorator";
import InstitutionalDocumentCategory from "@/models/InstitutionalDocumentCategory";
import InstitutionalDocumentCategoryService from "@/services/InstitutionalDocumentCategoryService";

@Component
export default class InstitutionalDocumentsView extends Vue {
    categories: InstitutionalDocumentCategory[] = []
    loading: boolean = false


    created() {
        InstitutionalDocumentCategoryService.getInstitutionalDocumentCategories(this, this.categories)
    }

}
